import '../css/index.scss'
import $ from 'jquery'

/**js**/
'use strict';

var windowWidth = $(window).width();
var windowHeight = $(window).height();
var BREAKPOINT_SP = 768;
var adjust = null;

window.addEventListener('load',function(){

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  if (windowWidth <= BREAKPOINT_SP) {
    adjust = windowHeight * .45;
  } else {
    adjust = windowHeight * .35;
  }

  const body = document.querySelector( 'body' );
  setTimeout(function(){
    body.classList.add('complete');
  }, 300);

});


// resize
var resizeTimer;
var interval = 1000;

window.addEventListener('resize', function() {

  if (resizeTimer !== false) {
    clearTimeout(resizeTimer);
  }

  resizeTimer = setTimeout(function() {
    windowHeight = $(window).height();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

  }, interval);

});

$(function(){

  // animate
  var current_scrollY;
  current_scrollY = $( window ).scrollTop();
  triggerAnimation(current_scrollY);

  $(window).on('scroll', function () {
    current_scrollY = $( window ).scrollTop();
    triggerAnimation(current_scrollY);
  });

  function triggerAnimation(scroll) {
    const scrollBottom = scroll + $(window).height();
    $('.animate').each(function() {
      const top = $(this).offset().top - adjust;
      if(scrollBottom > top) {
        $(this).addClass('is_animated');
      } else {
        // $(this).removeClass('is_animated');
      }
    })
  }

  /*----------------------------------------------------------
    drawer-nav
  ----------------------------------------------------------*/

  var gNavi = $('.drawer-nav');
  var gNaviButton = $('.js-drawer-nav');

  gNaviButton.on('click', function(){
    gNaviButton.toggleClass('is-open');
    gNavi.toggleClass('is-open');
    $('body').toggleClass('is-open');
  });

  $('.drawer-nav a[href^="#"]').on('click', function(){
    gNaviButton.toggleClass('is-open');
    gNavi.toggleClass('is-open');
    $('body').toggleClass('is-open');
  });

});